import theme from "../theme";
import styled from "@emotion/styled";
import React from "react";
import { useSpring, useTransition, animated } from "react-spring";

const StyledMobileMenuButton = styled.button`
  position: absolute;
  top: 25px;
  right: 10px;
  height: 30px;
  width: 50px;
  cursor: pointer;
  background-color: unset;
  visibility: visible;

  & p {
    margin: 0;
    font-family: ${theme.fonts.body};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights.none};
    font-size: ${theme.fontSizes.xs};
    background-color: none;
    color: ${theme.colors.white};
  }

  & span.chevron {
    display: block;
    margin: 5px 0 0 0;
    position: absolute;
    color: white;
    top: 2px;
    right: 13px;
  }

  &:focus {
    outline: none;
  }
`;

const MobileMenuButton: React.FC<{
  isOpen: boolean;
  onClick: (nextOpen: boolean) => void;
}> = (props) => {
  const mountedRef = React.useRef<any>(null);

  const chevronTransform = useSpring({
    transform: props.isOpen ? "rotateX(180deg)" : "rotateX(0deg)",
    top: props.isOpen ? "-20px" : "2px"
  });

  const menuButtonTextTransition = useTransition(props.isOpen, null, {
    from: { transform: "rotateX(180deg)", opacity: 0 },
    enter: { transform: "rotateX(0deg)", opacity: 1 },
    leave: { transform: "rotateX(180deg)", opacity: 0 },
    // Disable animation until after first render
    immediate: !mountedRef.current
  });

  return (
    <StyledMobileMenuButton
      ref={mountedRef}
      onClick={() => props.onClick(!props.isOpen)}
    >
      {menuButtonTextTransition.map(({ item, props: menuButtonProps, key }) =>
        item ? (
          <animated.p
            key={key}
            style={{
              ...menuButtonProps,
              top: "0px",
              right: "0px",
              position: "absolute"
            }}
          >
            CLOSE
          </animated.p>
        ) : (
          <animated.p
            key={key}
            style={{
              ...menuButtonProps,
              top: "0px",
              right: "0px",
              position: "absolute"
            }}
          >
            MENU
          </animated.p>
        )
      )}
      <animated.span
        style={chevronTransform}
        className={`chevron ${props.isOpen ? "open" : ""} `}
      >
        ▾
      </animated.span>
    </StyledMobileMenuButton>
  );
};

export default MobileMenuButton;

import React from "react";
import { Global, css } from "@emotion/react";
import theme from "../theme";
import emotionNormalize from "emotion-normalize";
export const GlobalCss: React.FC = () => {
  return (
    <Global
      styles={css`
        ${emotionNormalize}
        * {
          font-family: ${theme.fonts.body};
        }

        * {
          box-sizing: border-box;
        }

        // This allows us to constrain open images in <Gallery>
        div.react-images__view > img {
          max-height: 100vh;
        }

        body {
          margin: 0 8px 0 8px;
        }

        button {
          font-size: 100%;
          font-family: inherit;
          border: 0;
          padding: 0;
        }

        // Global theme style decision from arthur
        a,
        a:visited {
          cursor: pointer;
          color: ${theme.colors.palette[800]};
        }
        a:hover {
          color: ${theme.colors.palette[500]};
        }

        // Specialise links in the image viewer (black background)
        .react-images__footer__caption a {
          color: ${theme.colors.palette[200]};
        }
        .react-images__footer__caption a:hover {
          color: ${theme.colors.palette[400]};
        }
      `}
    />
  );
};

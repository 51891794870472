import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CONTACT_PAGE_PATH, NAV_PAGES } from "../config";
import theme from "../theme";
import { NavLink } from "../types";
import { GlobalCss } from "./GlobalCss";
import { Header, NAV_HEIGHT } from "./Header";
import { SocialMediaIcons } from "./SocialMediaIcons";
import { useMatch } from "@reach/router";

export const PAGE_MAX_WIDTH = 1000;

export interface PageProps {
  links: NavLink[];
  noFooter?: boolean;
  fullWidth?: boolean;
}

const currentYear = new Date().getFullYear();

const FOOTER_HEIGHT = "90px";

const StyledFooter = styled.footer`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: ${theme.space[4]} 0;
  height: ${FOOTER_HEIGHT};
  background: ${theme.colors.palette[900]};

  // Account for the extra out-padding of the margin
  width: calc(100% + ${theme.space[4]});

  // Widen the footer past the body's 8px left/right
  margin: 0 -${theme.space[2]};

  & p {
    text-align: center;
    color: ${theme.colors.white};
    font-size: ${theme.fontSizes.md};
    background: ${theme.colors.palette[900]};
    font-family: ${theme.fonts.heading};
  }

  & div.social-media-icons {
    margin-left: ${theme.space[2]};
  }
`;

const StyledMain = styled.main<Pick<PageProps, "fullWidth">>`
  max-width: ${(props) => (props.fullWidth ? "unset" : `${PAGE_MAX_WIDTH}px`)};
  min-height: calc(100vh - ${FOOTER_HEIGHT});

  // In full-width mode, account for user agent margins
  margin: ${(props) => (props.fullWidth ? `0 -${theme.space[2]}` : "auto")};
  padding-top: ${NAV_HEIGHT};
  background-color: ${theme.colors.palette[50]};

  padding-bottom: ${theme.space[8]};
`;

const StyledJamieCredit = styled.p`
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.sm} !important;
  margin: 0 auto;

  > a {
    font-family: ${theme.fonts.heading};
    color: ${theme.colors.palette[200]};
  }

  > a:hover {
    color: ${theme.colors.palette[400]};
  }
`;

const Page: React.FC<PageProps> = ({
  noFooter,
  children,
  fullWidth,
  links
}) => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allSitePage {
        edges {
          node {
            path
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isContactPage = useMatch(CONTACT_PAGE_PATH);

  return (
    <>
      <GlobalCss />
      <Header title={data.site.siteMetadata.title} links={links} />
      <StyledMain fullWidth={!!fullWidth}>{children}</StyledMain>
      {noFooter ? null : (
        <StyledFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div style={{ width: "80px" }} />
            <p>Arthur Bruce • {currentYear} </p>
            <SocialMediaIcons height={"56px"} width={"80px"} />
          </div>
          {isContactPage && (
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              <StyledJamieCredit>
                This website was built by{" "}
                <a
                  href="https://jamiestewart.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jamie Stewart
                </a>
              </StyledJamieCredit>
            </div>
          )}
        </StyledFooter>
      )}
    </>
  );
};

const DefaultNavPage: React.FC<Omit<PageProps, "links">> = (props) => (
  <Page {...props} links={[...NAV_PAGES]} />
);

export default DefaultNavPage;

import React from "react";
import styled from "@emotion/styled";

import { Instagram, Twitter } from "@icons-pack/react-simple-icons";
import { INSTAGRAM_TO, TWITTER_TO } from "../config";
import theme from "../theme";

const IconsWrapper = styled.div<{
  width: string;
  height: string;
  icons: number;
}>`
  width: ${(props) => `${props.width}`};
  display: flex;
  height: ${(props) => `${props.height}`};
  justify-content: space-evenly;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: ${({ icons }) => `calc(100% / ${icons})`};
    height: 100%;

    transition-property: background-color;
    transition-duration: ${theme.animation.speed.normal};
    transition-timing-function: ease-in;

    &:hover,
    &.active-link {
      background-color: #333;
    }
  }
`;

interface SocialMediaIconsProps {
  width: string;
  height: string;
}

export const SocialMediaIcons: React.FC<SocialMediaIconsProps> = ({
  width,
  height
}) => {
  return (
    <IconsWrapper
      width={width}
      height={height}
      className={"social-media-icons"}
      icons={1}
    >
      <a href={INSTAGRAM_TO} target={"_blank"} rel={"noreferrer"}>
        <Instagram style={{ color: "white", cursor: "pointer" }} />
      </a>
      <a href={TWITTER_TO} target={"_blank"} rel={"noreferrer"}>
        <Twitter style={{ color: "white", cursor: "pointer" }} />
      </a>
    </IconsWrapper>
  );
};

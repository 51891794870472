import { NavLink } from "./types";

// Not used currently
export const TWITTER_TO = "https://twitter.com/arthurgbruce";
export const INSTAGRAM_TO = "https://instagram.com/arthurgbruce";

export const CONTACT_PAGE_PATH = "/contact";

export const NAV_PAGES: readonly NavLink[] = [
  { title: "Home", path: "/" },
  { title: "Biography", path: "/biography" },
  { title: "Repertoire", path: "/repertoire" },
  { title: "Gallery", path: "/gallery" },
  { title: "Media", path: "/media" },
  { title: "Press", path: "/press" },
  { title: "Contact", path: CONTACT_PAGE_PATH }
] as const;

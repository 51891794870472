import styled from "@emotion/styled";
import { Link, navigate } from "gatsby";
import React from "react";
import { animated, useSpring } from "react-spring";
import theme from "../theme";
import { mediaQuery } from "../theme/breakpoints";
import { NavLink } from "../types";
import MobileMenuButton from "./MenuButton";
import { SocialMediaIcons } from "./SocialMediaIcons";

export interface HeaderProps {
  title: string;
  links: NavLink[];
}

export const NAV_HEIGHT = "60px";

const HAMBURGER_MENU_MIN_WIDTH = 960;
const HEADLINE_MIN_WIDTH = "302px";

const StyledHeader = styled.header`
  background-color: ${theme.colors.palette[900]};
  margin: 0 -${theme.space[2]} 0 -${theme.space[2]};
  height: ${NAV_HEIGHT};
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100vw;

  h1,
  h1 i {
    font-family: ${theme.fonts.heading};
    text-decoration: underline;
    color: ${theme.colors.white};
  }

  h1 {
    font-size: ${theme.fontSizes.xl};
    margin: 0 ${theme.space[3]};
    position: relative;
    top: 18px;
    cursor: pointer;
    display: inline;
    height: ${NAV_HEIGHT};
    min-width: ${HEADLINE_MIN_WIDTH};
  }

  ${mediaQuery("340px")} {
    h1 {
      font-size: ${theme.fontSizes["2xl"]};
      cursor: pointer;
      top: 15px;
    }
  }

  ${mediaQuery(HAMBURGER_MENU_MIN_WIDTH)} {
    display: flex;
    justify-content: space-between;

    // An equally-sized spacing div on the RH side of the links,
    // so that they are centered when there is enough space.
    .nav-spacer {
      width: ${HEADLINE_MIN_WIDTH};
      min-width: ${HEADLINE_MIN_WIDTH};
    }
  }
`;

const StyledNav = styled.nav<{ landscape: boolean }>`
  width: 100vw;
  display: flex;
  flex: 1;
  flex-direction: ${(props) => (props.landscape ? "row" : "column")};
  flex-wrap: ${(props) => (props.landscape ? "wrap" : "nowrap")};
  position: relative;
  will-change: transform;
  transform-origin: top;
  z-index: 1;
  top: 24px;

  > a {
    flex-basis: ${(props) => (props.landscape ? "50%" : "100%")};
  }

  ${mediaQuery(HAMBURGER_MENU_MIN_WIDTH)} {
    flex-direction: row;
    width: calc(100vw - ${HEADLINE_MIN_WIDTH});
    top: 0;
    max-width: 1000px;
  }
`;

const AnimatedNav = animated(StyledNav);

const StyledHeaderLink = styled(animated(Link))<{ landscape: boolean }>`
  font-family: ${theme.fonts.heading};
  color: white;
  text-decoration: none;
  height: 50px;
  padding: ${theme.space[4]} ${theme.space[3]};
  font-size: ${theme.fontSizes.md};
  background-color: ${theme.colors.palette[900]};
  border-bottom: 3px solid ${theme.colors.transparent};
  width: ${(props) => (props.landscape ? "50%" : "100vw")};

  transition-property: border-bottom-color, background-color;
  transition-duration: ${theme.animation.speed.normal};
  transition-timing-function: ease-in;

  &:hover,
  &.active-link {
    background-color: #333;
  }

  ${mediaQuery(HAMBURGER_MENU_MIN_WIDTH)} {
    height: 60px;
    text-align: center;
    width: 100%;
    padding-top: 22px;

    &:hover,
    &.active-link {
      border-bottom: 3px solid ${theme.colors.white};
    }
  }

  ${mediaQuery("1200px")} {
    padding-top: 20px;
    font-size: ${theme.fontSizes.lg};
  }

  ${mediaQuery("1600px")} {
    padding-top: 19px;
    font-size: ${theme.fontSizes.xl};
  }
`;

const SocialMediaLinksWrapper = styled(animated.div)<{ landscape: boolean }>`
  background-color: ${theme.colors.palette[900]};
  width: ${(props) => (props.landscape ? "50%" : "100vw")};

  > div > div:hover {
    background-color: #333;
  }
`;

// Use an animated.nav only when on the mobile breakpoint
const navElem = (mobileBp: boolean) =>
  function NavElem({ children, ...rest }: React.PropsWithChildren<any>) {
    return mobileBp ? (
      <AnimatedNav {...rest}>{children}</AnimatedNav>
    ) : (
      <StyledNav {...rest}>{children}</StyledNav>
    );
  };

export const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [mobileMenuBp, setMobileMenuBp] = React.useState(true);
  const [mobileBpLandscape, setMobilBpLandscape] = React.useState(false);

  // Set the initial values of these fields, in a context that will always
  // have access to the window object
  React.useLayoutEffect(() => {
    const shouldBeMobileMenu = window.innerWidth <= HAMBURGER_MENU_MIN_WIDTH;
    setMobileMenuBp(shouldBeMobileMenu);
    setMobilBpLandscape(
      shouldBeMobileMenu ? window.innerWidth > window.innerHeight : false
    );
  }, []);

  // When view becomes wide enough, show the nav links if hidden,
  // and when narrow enough, hide them if shown
  React.useLayoutEffect(() => {
    const handleWindowResize = () => {
      const width = window.innerWidth;
      if (width > HAMBURGER_MENU_MIN_WIDTH) {
        setMobileMenuBp(false);
        setMobilBpLandscape(false);
      } else {
        // If the mobile view is landscape (width > height) {
        if (window.innerWidth > window.innerHeight) {
          setMobilBpLandscape(true);
        } else {
          setMobilBpLandscape(false);
        }

        setMobileMenuBp(true);
        setMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const menuTransform = useSpring({
    to: {
      transform: menuOpen ? "scaleY(1)" : "scaleY(0)"
    },
    // Disable animation if _NOT_ on mobile breakpoint
    immediate: !mobileMenuBp
  });

  const menuItemFade = useSpring({ color: menuOpen ? "white" : "black" });

  const NavElem = navElem(mobileMenuBp);

  return (
    <StyledHeader>
      <h1 onClick={() => navigate("/")}>
        Arthur Bruce - <i>Baritone</i>
      </h1>
      <NavElem
        landscape={mobileBpLandscape}
        style={mobileMenuBp ? menuTransform : { transform: "scaleY(1)" }}
      >
        {props.links.map((nl, idx) => (
          <StyledHeaderLink
            landscape={mobileBpLandscape}
            style={mobileMenuBp ? menuItemFade : { color: "white" }}
            key={`nav-link-${idx} `}
            to={nl.path}
            activeClassName={"active-link"}
          >
            {nl.title}
          </StyledHeaderLink>
        ))}
        {mobileMenuBp && (
          <SocialMediaLinksWrapper landscape={mobileBpLandscape}>
            <SocialMediaIcons height={"50px"} width={"100%"} />
          </SocialMediaLinksWrapper>
        )}
      </NavElem>
      {mobileMenuBp ? (
        <MobileMenuButton isOpen={menuOpen} onClick={setMenuOpen} />
      ) : (
        <div className="nav-spacer" />
      )}
    </StyledHeader>
  );
};
